import { sanitizeHtml } from '@local/web-design-system/dist/components/HtmlSection/sanitizeHtml';
import { getConfig } from 'src/components/AppConfig';

const openStreetMapDefaultUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const openStreetMapDefaultAttribution =
    '&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

export enum FeatureFlag {
    PROJECT_COORDINATE_SYSTEM = 'projectCoordinateSystem',
}

export interface DebugConfig {
    reduxLogger: boolean;
    reduxDevTools: boolean;
    development: boolean;
}

export interface FilesConfig {
    maxSize: number;
    multipartUploadConcurrency: number;
}

export interface MixpanelConfig {
    token: string;
}

export enum Environment {
    Development = 'development',
    Staging = 'staging',
    Production = 'production',
}

export interface MapConfig {
    openStreetMapUrl: string;
    openStreetMapAttribution: string;
    azureClientId: string;
}

export interface UserPortalConfig {
    debug: DebugConfig;
    files: FilesConfig;
    featureFlags: string;
    sentry: boolean;
    sentryEnvironment: Environment;
    notificationsUrl: string;
    notificationPreferences: boolean;
    versioning: boolean;
    launchDarklyClientId: string;
    map: MapConfig;
    classicMXDepositBaseUrl: string;
    mixpanel: MixpanelConfig;
}

export function notificationPrefEnabled() {
    const config = getConfig() as unknown as UserPortalConfig;
    return config.notificationPreferences;
}

export function versioningEnabled() {
    const config = getConfig() as unknown as UserPortalConfig;
    return config.versioning;
}

export function featureFlagEnabled(flag: FeatureFlag) {
    const config = getConfig() as unknown as UserPortalConfig;
    return Boolean(config?.featureFlags?.includes(flag));
}

export function openStreetMapUrl() {
    const config = getConfig() as unknown as UserPortalConfig;
    return config?.map?.openStreetMapUrl ?? openStreetMapDefaultUrl;
}

export function openStreetMapAttribution() {
    const config = getConfig() as unknown as UserPortalConfig;
    return sanitizeHtml(config?.map?.openStreetMapAttribution ?? openStreetMapDefaultAttribution);
}
